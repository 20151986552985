import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { connect } from 'react-redux';
import Carousel from 're-carousel';
import Image from './Image';
import { defineMessages } from '#utils/i18n/intl';
import intl from 'react-intl-universal';
// import debounce from 'lodash/debounce';

import getBrandPictures from '#models/login/selectors/getBrandPictures';

// const Img = styled.img`
//   width: 100%;
//   height: 100%;
//   object-fit: cover;
// `;

// const Img = styled.div`
//   width: 100%;
//   height: 100%;
//   background-color: #F5F6FA;
//   background-image: url('${props => props.imgSrc}');
//   background-size: cover;
//   background-position: center;
// `;

const Dot = styled.span`
  display: inline-block;
  vertical-align: middle;
  height: 8px;
  width: 24px;
  opacity: ${props => (props.selected ? '0.9' : '0.3')};
  border-radius: 4px;
  background-color: #4B7DF9;
  margin: 7px 4px;
  transition-duration: 300ms;
  cursor: pointer;
`;

const ImgWrapper = styled.div`
  width: 100%;
  height: 100%;
`;

const Link = styled.a.attrs({
  href: props => props.href,
  target: props => props.target,
})`
  position: absolute;
  left: 50%;
  bottom: 96px;
  margin-left: -56px;
  display: inline-block;
  width: 112px;
  font-size: 16px;
  font-weight: 500;
  height: 38px;
  line-height: 38px;
  text-align: center;
  border: 1px solid #C8C8C8;
  border-radius: 20px;
  color: #000;
  cursor: pointer;
  text-decoration:none;
`;
const messages = defineMessages({
  view: {
    id: 'Scode.2041108',
    defaultMessage: '查看详情',
  },
});
const IndicatorDots = (props) => {
  const style = {
    position: 'absolute',
    zIndex: '100',
    bottom: '24px',
    textAlign: 'center',
    left: 0,
    right: 0,
    // marginLeft: '-80px',
  };
  const {
    total, index, prevHandler, nextHandler,
  } = props;
  if (total < 2) {
    // Hide dots when there is only one dot.
    return null;
  }
  const fnCallBack = (fn, times) => {
    if (times) {
      fn();
      setTimeout(() => {
        const time = times - 1;
        fnCallBack(fn, time);
      }, 300);
    }
  };
  const bannerChange = async (clickIndex) => {
    const interval = clickIndex - index;
    let doFn;
    if (interval > 0) {
      doFn = nextHandler;
    } else {
      doFn = prevHandler;
    }
    const times = Math.abs(interval);
    fnCallBack(doFn, times);
  };
  return (
    <div style={style}>
      {
        // eslint-disable-next-line react/no-array-index-key
        Array(...Array(total)).map((x, i) => {
          return <Dot key={i} selected={index === i} onClick={() => { bannerChange(i); }} />;
        })
      }
    </div>
  );
};

IndicatorDots.propTypes = {
  index: PropTypes.number.isRequired,
  total: PropTypes.number.isRequired,
  prevHandler: PropTypes.func.isRequired,
  nextHandler: PropTypes.func.isRequired,
};

const styles = {
  wrapper: {
    position: 'absolute',
    width: '100%',
    zIndex: '100',
    bottom: '0',
    textAlign: 'center',
  },
  btn: {
    width: '30px',
    height: '30px',
    cursor: 'pointer',
    userSelect: 'none',
    position: 'absolute',
    bottom: '0',
    font: '16px/30px sans-serif',
    color: 'rgba(255,255,255,0.8)',
  },
  left: {
    left: '0',
  },
  right: {
    right: '0',
  },
};
const Buttons = (props) => {
  const prevBtnStyle = Object.assign({}, styles.btn, styles.left);
  const nextBtnStyle = Object.assign({}, styles.btn, styles.right);
  const {
    index, total, loop, prevHandler, nextHandler,
  } = props;
  return (
    <div style={styles.wrapper}>
      { (loop || index !== 0) && (
        <div style={prevBtnStyle} onClick={prevHandler}>◀</div>
      )}
      { (loop || index !== total - 1) && (
        <div style={nextBtnStyle} onClick={nextHandler}>▶</div>
      )}
    </div>
  );
};
Buttons.propTypes = {
  index: PropTypes.number.isRequired,
  total: PropTypes.number.isRequired,
  loop: PropTypes.bool.isRequired,
  prevHandler: PropTypes.func.isRequired,
  nextHandler: PropTypes.func.isRequired,
};
// const BrandCarousel = ({ pictureList }) => (
//   <Carousel auto loop interval={2000} widgets={[IndicatorDots]}>
//     {
//       // eslint-disable-next-line react/no-array-index-key
//       pictureList.map((pictureItem, index) => (
//         // eslint-disable-next-line react/no-array-index-key
//         <Img key={index} imgSrc={pictureItem.picture_url} />
//       ))
//     }
//   </Carousel>
// );
class BrandCarousel extends PureComponent {
  // resizeHandler = debounce((e) => {
  //   this.setState(preState => ({ auto: !preState.auto }));
  // }, 300, {
  //   leading: true,
  //   trailing: false,
  // })

  // componentDidMount() {
  //   window.addEventListener('resize', this.resizeHandler);
  // }

  // componentWillUnmount() {
  //   window.removeEventListener('resize', this.resizeHandler);
  // }

  // resizeHandler = (e) => {
  //   const { auto } = this.state;
  //   auto && this.setState({ auto: false });
  //   clearTimeout(this.timingFlag);
  //   this.timingFlag = setTimeout(() => {
  //     this.setState(() => ({ auto: true }));
  //   }, 300);
  // }

  render() {
    const { pictureList } = this.props;
    return (
      pictureList.length > 1
        ? (
          <Carousel
            auto
            loop
            interval={4000}
            widgets={[IndicatorDots]}
          >
            {
            // eslint-disable-next-line react/no-array-index-key
            pictureList.map((pictureItem, index) => (
              // eslint-disable-next-line react/no-array-index-key
              <ImgWrapper key={index}>
                <Image imgSrc={pictureItem.picture_url} />
                {
                  pictureItem.element_refer_url
                    ? <Link href={pictureItem.element_refer_url} target="_blank">{intl.formatMessage(messages.view)}</Link>
                    : null
                }
              </ImgWrapper>
            ))
          }
          </Carousel>
        )
        : (
          <ImgWrapper>
            <Image key={0} imgSrc={pictureList[0].picture_url} />
            {
              pictureList[0].element_refer_url
                ? <Link href={pictureList[0].element_refer_url} target="_blank">{intl.formatMessage(messages.view)}</Link>
                : null
            }
          </ImgWrapper>
        )
    );
  }
}

BrandCarousel.propTypes = {
  pictureList: PropTypes.arrayOf(PropTypes.shape({
    picture_url: PropTypes.string.isRequired,
  })).isRequired,
};

function mapStateToProps(state) {
  return {
    // pictureList: state.brandInfo.pictures,
    pictureList: getBrandPictures(state),
  };
}

export default connect(mapStateToProps)(BrandCarousel);
