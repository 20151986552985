import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import intl from 'react-intl-universal';
import { defineMessages } from '#utils/i18n/intl';

import { get } from '#utils/globalVariable';
import Input from '#components/Input';
import Button from '#components/Button';
import SubMsg from '#components/submitMsg';
import RegisterAndPrivacy from '#components/RegisterAndPrivacy';

import {
  setSmsName,
  setSmsCode,
  jumpToVerificationCodeHelpMessage,
  setBrandError,
  setNetworkError,
  handleFatalError,
  submitMssage,
  protocalChanged,
} from '#models/login/action';


const messages = defineMessages({
  passwordPlaceholder: {
    id: 'SmsName.accountPlaceholder',
    defaultMessage: '手机号/邮箱',
  },
  validateCodePlaceholder: {
    id: 'ForgetPassword.validateCodePlaceholder',
    defaultMessage: '请输入您的验证码',
  },
  //  language
  login: {
    id: 'Login.login',
    defaultMessage: '登 录',
  },
  unreachableVerificationCode: {
    id: 'ForgetPassword.unreachableVerificationCode',
    defaultMessage: '没有收到验证码？',
  },
  askForHelp: {
    id: 'ForgetPassword.askForHelp',
    defaultMessage: '查看帮助',
  },
  helpMsgTitle: {
    id: 'ForgetPassword.helpMsgTitle',
    defaultMessage: '没有收到验证码怎么办？',
  },
  helpMsgLine_1: {
    id: 'ForgetPassword.helpMsgLine_1',
    defaultMessage: '亲爱的用户，验证码短信/邮件正常都会在数秒钟内发送，如果您未收到短信/邮件，请参照如下常见情况进行解决：',
  },
  helpMsgLine_2: {
    id: 'ForgetPassword.helpMsgLine_2',
    defaultMessage: '1、由于您的手机或邮箱软件设定了某些安全设置，验证码短信/邮件可能被拦截进了垃圾箱。请打开垃圾箱查看，并将HIK eSales号码添加为白名单。',
  },
  helpMsgLine_3: {
    id: 'ForgetPassword.helpMsgLine_3',
    defaultMessage: '2、由于运营商通道故障造成了短信/邮件发送时间延迟，请耐心稍候片刻或点击重新获取验证码。',
  },
  helpMsgLine_4: {
    id: 'ForgetPassword.helpMsgLine_4',
    defaultMessage: '3、关于手机号验证，目前支持移动、联通和电信的所有号码，暂不支持国际及港澳台地区号码。',
  },
  helpMsgLine_5_1: {
    id: 'ForgetPassword.helpMsgLine_5_1',
    defaultMessage: '如果您尝试了上述方式后均未解决，或存有其他疑问，请通过热线电话',
  },
  helpMsgLine_5_2: {
    id: 'ForgetPassword.helpMsgLine_5_2',
    defaultMessage: '获取客户协助。',
  },
});

class NumberLogin extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
    };
  }

  render() {
    const {
      onVerificationClick,
      onUsernameInput,
      onVerificationCodeInput,
      onPressEnter,
      smsName,
      smsvificationCode,
      buttonLoading,
      forgetPasswordError,
      onProtoChanged,
    } = this.props;

    return (
      <>
        <div>
          <Input
            value={smsName}
            placeholder={intl.formatMessage(messages.passwordPlaceholder)}
            onChange={onUsernameInput}
            onKeyUp={onPressEnter}
            type="text"
            data-ta-key="email_input"
            pageType="login"
            autoFocus={get('env') === 'WEB'}
          />
          <Input
            value={smsvificationCode}
            placeholder={intl.formatMessage(messages.validateCodePlaceholder)}
            onChange={onVerificationCodeInput}
            onKeyUp={onPressEnter}
            type="text"
            errorMsg={forgetPasswordError}
            data-ta-key="password_input"
          >
            <SubMsg />
          </Input>
          <RegisterAndPrivacy isLoginPage handleChange={onProtoChanged} />
          {/* 验证身份 */}
          {/* <div> */}
          <Button
            loading={buttonLoading}
            active={smsName && smsvificationCode}
            onClick={onVerificationClick}
            data-ta-key="login_btn"
            isLoginPage
          >
            {intl.formatMessage(messages.login)}
          </Button>
          {/* </div> */}
        </div>
      </>
    );
  }
}

NumberLogin.propTypes = {
  onVerificationClick: PropTypes.func.isRequired,
  onUsernameInput: PropTypes.func.isRequired,
  onVerificationCodeInput: PropTypes.func.isRequired,
  onPressEnter: PropTypes.func.isRequired,
  smsName: PropTypes.string.isRequired,
  smsvificationCode: PropTypes.string.isRequired,
  onProtoChanged: PropTypes.func.isRequired,
  buttonLoading: PropTypes.bool.isRequired,
  forgetPasswordError: PropTypes.string,
};

NumberLogin.defaultProps = {
  forgetPasswordError: null,
};

// connect组件
function mapStateToProps(state) {
  return {
    buttonLoading: state.forgetPasswordLoading,
    forgetPasswordError: state.forgetPasswordError,
    tenantError: state.tenantError,
    registerAddressError: state.registerAddressError,
    brandError: state.brandError,
    networkError: state.networkError,
    fatalError: state.fatalError,
    smsName: state.smsName,
    smsvificationCode: state.smsvificationCode,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    onVerificationClick() {
      // 短信登录
      dispatch(submitMssage());
    },
    onUsernameInput(e) {
      dispatch(setSmsName(e.target.value.trim()));
    },
    onVerificationCodeInput(e) {
      dispatch(setSmsCode(e.target.value.trim()));
    },
    onCloseErrorModal() {
      dispatch(setBrandError(null));
      dispatch(setNetworkError(null));
      dispatch(handleFatalError());
    },
    onProtoChanged(e) {
      dispatch(protocalChanged(e.target.checked));
    },
    onOpenHelpModal() {
      dispatch(jumpToVerificationCodeHelpMessage(true));
    },
    onCloseHelpModal() {
      dispatch(jumpToVerificationCodeHelpMessage(false));
    },
    onPressEnter(e) {
      if (e.keyCode === 13) {
        dispatch(submitMssage());
      }
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(NumberLogin);
