/* eslint-disable */
import {
  combineReducers
} from "redux";
import {
  loginStates,
  SET_USERNAME,
  SET_SMSNAME,
  CLEAR_USERNAME,
  SET_PASSWORD,
  SET_NEW_PASSWORD,
  SET_NEW_PASSWORD_AGAIN,
  SET_NEW_PASSWORD_ERROR,
  SET_SECURE_CODE,
  SET_VERIFICATION_CODE,
  SET_SMS_CODE,
  SET_SHOW_VERIFICATION_CODE_HELP_MESSAGE,
  SET_IS_VERIFICATION_CODE_LOADING,
  SET_FORGET_PASSWORD_ERROR,
  SET_RESET_PASSWORD_FLAG,
  SET_PASSWORD_RULES,
  SET_PASSWORD_RULES_ERROR,
  SET_PASSWORD_TIMES,
  SET_PASSPORT,
  SET_PASSPORT_ERROR,
  SET_TENANT_ERROR,
  SET_REGISTER_ADDRESS_ERROR,
  SET_SSO_ERROR,
  SET_BRAND_ERROR,
  SET_NETWORK_ERROR,
  SET_FATAL_ERROR,
  SET_LOGIN_LOADING,
  SET_RESET_PASSWORD_LOADING,
  SET_FORGET_PASSWORD_LOADING,
  SET_AUTH_CODE_INSTANCE,
  SET_NECAPTCHA_VALIDATE,
  SELECT_TENANT,
  CLEAR_SELECTED_TENANT,
  CREATE_TENANT_LIST,
  SET_LOGIN_STATE,
  SET_MOBILE_PARAMS,
  SET_HAS_INITIALIZED,
  SET_SCOPE,
  SET_SUB_DOMAIN,
  SET_REGISTER_ADDRESS,
  SET_SSO_INFO,
  SET_BRAND_INFO,
  SET_GLOBAL_ERROR,
  VERIFY_CODE_ERROR,
  PROTOCAL_CHANGED,
  SET_DATA_LIST,
  MSG_SEND,
  SET_LOGIN_TYPE,
  SET_FORGET_PASSWORD_RECEIVER,
  SET_FORGET_PASSWORD_TIPS_SHOW,
  SET_FORGET_PASSWORD_TIPS
} from "./action";

import androidQRcode from "#static/img/andriod_QRcode.png";
import iOSQRcode from "#static/img/ios_QRcode.png";
// import brand1 from '#static/img/brand1.png';
// import brand2 from '#static/img/brand2.png';

export const initialState = {
  username: localStorage.getItem("lastLoginUserId") || "",
  smsName: localStorage.getItem("smsName")||'',
  password: "",
  newPassword: "",
  newPasswordAgain: "",
  newPasswordError: null,
  secureCode: "",
  verificationCode: "",
  smsvificationCode: "",
  showVerificationCodeHelpMessage: false,
  isVerificationCodeLoading: false,
  forgetPasswordError: null,
  forgetPasswordReceiver: null,
  forgetPasswordTips: null,
  resetPasswordFlag: "",
  passwordRules: "6-16位。必须包含数字、大小写字母和特殊字符",
  passwordRulesError: null,
  passport: "",
  passportError: null,
  passwordTimes: null,
  tenantError: null,
  registerAddressError: null,
  SSOError: null,
  brandError: null,
  networkError: null,
  fatalError: null,
  verifyCodeError: null,
  loginLoading: false,
  resetPasswordLoading: false,
  forgetPasswordLoading: false,
  authCodeInstance: null,
  NECaptchaValidate: null,
  checkBoxRes: false,
  tenantList: {
    byId: {},
    allIds: [],
  },
  // loginState: loginStates.LOGINING,
  loginState: null,
  mobileParams: {
    login_type: "web",
  },
  scope: "all",
  subDomain: null,
  hasInitialized: false,
  registerAddress: null,
  SSOInfo: {},
  brandInfo: {
    color: "#f02c38",
    logo: {
      picture_url: '',
    },
    pictures: [
      // {
      //   picture_url: brand1,
      // },
      // {
      //   picture_url: brand2,
      // },
    ],
    androidQRcode,
    iOSQRcode,
  },
  globalError: null,
  qywxDataList: [],
  loginType:'',
  forgetPasswordShowTips:false
};

// function login(state = initialState, action){
//   return {
//     username: username(state.username, action),
//     password: password(state.password, action),
//     tenantList: tenantList(state.tenantList, action),
//     loginState: loginState(state.loginState, action),
//   }
// }

export function username(state = initialState.username, action) {
  switch (action.type) {
    case SET_USERNAME:
      return action.payload;
    case CLEAR_USERNAME:
      return "";
    default:
      return state;
  }
}

export function smsName(state = initialState.smsName, action ) {
  switch (action.type) {
    case SET_SMSNAME:
      return action.payload;
    case CLEAR_USERNAME:
      return "";
    default:
      return state;
  }
}

export function password(state = initialState.password, action) {
  switch (action.type) {
    case SET_PASSWORD:
      return action.payload;
    default:
      return state;
  }
}

export function newPassword(state = initialState.newPassword, action) {
  switch (action.type) {
    case SET_NEW_PASSWORD:
      return action.payload;
    default:
      return state;
  }
}

export function newPasswordAgain(
  state = initialState.newPasswordAgain,
  action
) {
  switch (action.type) {
    case SET_NEW_PASSWORD_AGAIN:
      return action.payload;
    default:
      return state;
  }
}

export function newPasswordError(
  state = initialState.newPasswordError,
  action
) {
  switch (action.type) {
    case SET_NEW_PASSWORD_ERROR:
      return action.payload;
    default:
      return state;
  }
}

export function secureCode(state = initialState.secureCode, action) {
  switch (action.type) {
    case SET_SECURE_CODE:
      return action.payload;
    default:
      return state;
  }
}

export function verificationCode(
  state = initialState.verificationCode,
  action
) {
  switch (action.type) {
    case SET_VERIFICATION_CODE:
      return action.payload;
    default:
      return state;
  }
}
// 短信验证码
export function smsvificationCode(
  state = initialState.smsvificationCode, action
) {
  switch(action.type) {
    case SET_SMS_CODE:
      return action.payload;
    default:
      return state;
  }
}

export function showVerificationCodeHelpMessage(
  state = initialState.showVerificationCodeHelpMessage,
  action
) {
  switch (action.type) {
    case SET_SHOW_VERIFICATION_CODE_HELP_MESSAGE:
      return action.payload;
    default:
      return state;
  }
}

export function isVerificationCodeLoading(
  state = initialState.isVerificationCodeLoading,
  action
) {
  switch (action.type) {
    case SET_IS_VERIFICATION_CODE_LOADING:
      return action.payload;
    default:
      return state;
  }
}

export function forgetPasswordError(
  state = initialState.forgetPasswordError,
  action
) {
  switch (action.type) {
    case SET_FORGET_PASSWORD_ERROR:
      return action.payload;
    default:
      return state;
  }
}

export function forgetPasswordReceiver(
  state = initialState.forgetPasswordReceiver,
  action
) {
  switch (action.type) {
    case SET_FORGET_PASSWORD_RECEIVER:
      return action.payload;
    default:
      return state;
  }
}

export function forgetPasswordShowTips(
  state = initialState.forgetPasswordShowTips,
  action
) {
  switch (action.type) {
    case SET_FORGET_PASSWORD_TIPS_SHOW:
      return action.payload;
    default:
      return state;
  }
}

export function forgetPasswordTips(
  state = initialState.forgetPasswordTips,
  action
) {
  switch (action.type) {
    case SET_FORGET_PASSWORD_TIPS:
      return action.payload;
    default:
      return state;
  }
}

export function resetPasswordFlag(
  state = initialState.resetPasswordFlag,
  action
) {
  switch (action.type) {
    case SET_RESET_PASSWORD_FLAG:
      return action.payload;
    default:
      return state;
  }
}

export function passwordRules(state = initialState.passwordRules, action) {
  switch (action.type) {
    case SET_PASSWORD_RULES:
      return action.payload;
    default:
      return state;
  }
}

export function passwordTimes(state = initialState.passwordTimes, action) {
  switch (action.type) {
    case SET_PASSWORD_TIMES:
      return action.payload;
    default:
      return state;
  }
}

export function passwordRulesError(
  state = initialState.passwordRulesError,
  action
) {
  switch (action.type) {
    case SET_PASSWORD_RULES_ERROR:
      return action.payload;
    default:
      return state;
  }
}

function checkBoxRes(state = initialState.checkBoxRes, action) {
  switch (action.type) {
    case PROTOCAL_CHANGED:
      return action.payload;
    default:
      return state;
  }
}

export function passport(state = initialState.passport, action) {
  switch (action.type) {
    case SET_PASSPORT:
      return action.payload;

    default:
      return state;
  }
}

export function passportError(state = initialState.passportError, action) {
  switch (action.type) {
    case SET_PASSPORT_ERROR:
      return action.payload;
    default:
      return state;
  }
}

export function tenantError(state = initialState.tenantError, action) {
  switch (action.type) {
    case SET_TENANT_ERROR:
      return action.payload;
    default:
      return state;
  }
}

export function registerAddressError(
  state = initialState.registerAddressError,
  action
) {
  switch (action.type) {
    case SET_REGISTER_ADDRESS_ERROR:
      return action.payload;
    default:
      return state;
  }
}

export function SSOError(state = initialState.SSOError, action) {
  switch (action.type) {
    case SET_SSO_ERROR:
      return action.payload;
    default:
      return state;
  }
}

export function brandError(state = initialState.brandError, action) {
  switch (action.type) {
    case SET_BRAND_ERROR:
      return action.payload;
    default:
      return state;
  }
}

export function networkError(state = initialState.networkError, action) {
  switch (action.type) {
    case SET_NETWORK_ERROR:
      return action.payload;
    default:
      return state;
  }
}

export function fatalError(state = initialState.fatalError, action) {
  switch (action.type) {
    case SET_FATAL_ERROR:
      return action.payload;
    default:
      return state;
  }
}

export function verifyCodeError(state = initialState.verifyCodeError, action) {
  switch (action.type) {
    case VERIFY_CODE_ERROR:
      return action.payload;
    default:
      return state;
  }
}

export function loginLoading(state = initialState.loginLoading, action) {
  switch (action.type) {
    case SET_LOGIN_LOADING:
      return action.payload;
    default:
      return state;
  }
}

export function resetPasswordLoading(
  state = initialState.resetPasswordLoading,
  action
) {
  switch (action.type) {
    case SET_RESET_PASSWORD_LOADING:
      return action.payload;
    default:
      return state;
  }
}

export function forgetPasswordLoading(
  state = initialState.forgetPasswordLoading,
  action
) {
  switch (action.type) {
    case SET_FORGET_PASSWORD_LOADING:
      return action.payload;
    default:
      return state;
  }
}

export function authCodeInstance(
  state = initialState.authCodeInstance,
  action
) {
  switch (action.type) {
    case SET_AUTH_CODE_INSTANCE:
      return action.payload;

    default:
      return state;
  }
}

export function NECaptchaValidate(
  state = initialState.NECaptchaValidate,
  action
) {
  switch (action.type) {
    case SET_NECAPTCHA_VALIDATE:
      return action.payload;

    default:
      return state;
  }
}

export function tenantList(state = initialState.tenantList, action) {
  switch (action.type) {
    case CREATE_TENANT_LIST: {
      const dataList = action.payload;
      const byId = {};
      const allIds = [];
      dataList.forEach((data) => {
        byId[data.id] = {
          ...data,
          selected: false
        };
        allIds.push(data.id);
      });
      return {
        byId,
        allIds,
      };
    }
    case SELECT_TENANT: {
      const byId = {};
      Object.keys(state.byId).forEach((id) => {
        if (id === action.payload) {
          byId[id] = {
            ...state.byId[id],
            selected: !state.byId[id].selected
          };
        } else if (!state.byId[id].selected) {
          byId[id] = state.byId[id];
        } else {
          byId[id] = {
            ...state.byId[id],
            selected: false
          };
        }
      });
      return {
        byId,
        allIds: state.allIds,
      };
    }
    case CLEAR_SELECTED_TENANT: {
      const byId = {};
      Object.keys(state.byId).forEach((id) => {
        if (!state.byId[id].selected) {
          byId[id] = state.byId[id];
        } else {
          byId[id] = {
            ...state.byId[id],
            selected: false
          };
        }
      });
      return {
        byId,
        allIds: state.allIds,
      };
    }
    default:
      return state;
  }
}
// 企业微信租户列表
function qywxDataList(state = initialState.qywxDataList, action) {
  switch (action.type) {
    // 选择租户列表数据
    case SET_DATA_LIST: {
      return action.payload
    }
    default:
      return state;
  }
}
export function loginState(state = initialState.loginState, action) {
  switch (action.type) {
    case SET_LOGIN_STATE:
      return action.payload;
    default:
      return state;
  }
}

export function mobileParams(state = initialState.mobileParams, action) {
  switch (action.type) {
    case SET_MOBILE_PARAMS:
      return {
        ...state, ...action.payload
      };
    default:
      return state;
  }
}

export function scope(state = initialState.scope, action) {
  switch (action.type) {
    case SET_SCOPE:
      return action.payload;
    default:
      return state;
  }
}

export function loginType(state = initialState.loginType, action){
  switch (action.type) {
    case SET_LOGIN_TYPE:
      return action.payload;
    default:
      return state;
  }
}

export function subDomain(state = initialState.subDomain, action) {
  switch (action.type) {
    case SET_SUB_DOMAIN:
      return action.payload;
    default:
      return state;
  }
}

export function hasInitialized(state = initialState.hasInitialized, action) {
  switch (action.type) {
    case SET_HAS_INITIALIZED:
      return action.payload;
    default:
      return state;
  }
}

export function registerAddress(state = initialState.registerAddress, action) {
  switch (action.type) {
    case SET_REGISTER_ADDRESS:
      return action.payload;
    default:
      return state;
  }
}

export function SSOInfo(state = initialState.SSOInfo, action) {
  switch (action.type) {
    case SET_SSO_INFO:
      return {
        ...state, ...action.payload
      };
    default:
      return state;
  }
}

export function brandInfo(state = initialState.brandInfo, action) {
  switch (action.type) {
    case SET_BRAND_INFO:
      return {
        ...state, ...action.payload
      };
    default:
      return state;
  }
}

function globalError(state = initialState.globalError, action) {
  switch (action.type) {
    case SET_GLOBAL_ERROR:
      return action.payload;
    default:
      return state;
  }
}

export default combineReducers({
  username,
  smsName,
  password,
  newPassword,
  newPasswordAgain,
  newPasswordError,
  secureCode,
  verificationCode,
  smsvificationCode,
  showVerificationCodeHelpMessage,
  isVerificationCodeLoading,
  forgetPasswordError,
  forgetPasswordReceiver,
  resetPasswordFlag,
  passwordRules,
  passwordTimes,
  passwordRulesError,
  passport,
  passportError,
  tenantError,
  registerAddressError,
  SSOError,
  brandError,
  networkError,
  fatalError,
  verifyCodeError,
  loginLoading,
  resetPasswordLoading,
  forgetPasswordLoading,
  authCodeInstance,
  NECaptchaValidate,
  tenantList,
  loginState,
  mobileParams,
  scope,
  subDomain,
  hasInitialized,
  registerAddress,
  SSOInfo,
  brandInfo,
  globalError,
  checkBoxRes,
  qywxDataList,
  loginType,
  forgetPasswordShowTips,
  forgetPasswordTips
});