/* eslint-disable */
import React from "react";
import axios from 'axios'
import queryString from 'query-string';
import Loading from '#static/img/txhyLoadingIcon.gif';
import styled from "styled-components";

const IconWrapper = styled.img`
  display: block;
  margin: 100px auto;
`;
export default class WemeetMain extends React.PureComponent {
  constructor(props) {
    super(props)
    this.state = {
       
    }
};
  componentDidMount() {
    const { app_id,return_uri } = queryString.parse(window.location.search); 
    document.title = "HIK eSales";
    let encode_url = encodeURIComponent(return_uri)
    console.log(app_id,return_uri,encode_url)
    this.getwemeetCode(app_id,encode_url)
  }
  
  async getwemeetCode(app_id,return_uri) {
    console.log('使用sdk 获取 code ===')
    await window.wemeet.permission.requestAgentAuthCode({
        sdkId: app_id,
      })
        .then((resp) => {
          console.log(resp)
          var code = resp.authCode;
           window.location.href = "/auc/wemeet/auth?app_id=" + app_id + "&code=" + code + "&return_uri=" + return_uri;
        })
        .catch((err) => {
          console.log(err)
        });

}
  render() {
    return (
      <div>
        <IconWrapper src={Loading} />
      </div>
    );
  }
}

