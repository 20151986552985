import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
// import { withRouter } from 'react-router-dom';
import {
  fetchVerificationCode,
  setIsVerificationCodeLoading,
} from '#models/login/action';
import { defineMessages } from '#utils/i18n/intl';
import intl from 'react-intl-universal';

const messages = defineMessages({
  text: {
    id: 'Verification.text',
    defaultMessage: '获取验证码',
  },
  againText: {
    id: 'Verification.againText',
    defaultMessage: '重新发送验证码',
  },
  time: {
    id: 'Verification.time',
    defaultMessage: '{time}s',
  },
});

const Wrapper = styled.span`
  font-size: 12px;
  color: ${props => (props.isLoading ? '#6A7280' : '#629DF9')};
  line-height: 18px;
  cursor: pointer;
`;

// const VerificationCode = ({ onForgetPassword }) => (
//   <Wrapper onClick={onForgetPassword}>
//     {intl.formatMessage(messages.text)}
//   </Wrapper>
// );
const REMAINING_TIME = 60;
class VerificationCode extends Component {
  state = {
    remainingTime: REMAINING_TIME,
    initialFlag: true,
  }

  componentDidUpdate() {
    const { isLoading } = this.props;
    if (isLoading) {
      this.decreaseTime();
    }
  }

  componentWillUnmount() {
    const { onResetLoading } = this.props;
    onResetLoading();
  }

  handleClick = () => {
    const { isLoading, onForgetPassword } = this.props;
    isLoading || onForgetPassword();
  }

  decreaseTime = () => {
    const { remainingTime } = this.state;
    const { onResetLoading } = this.props;

    // 开始倒计时
    if (remainingTime >= 0) {
      setTimeout(() => {
        // 设置初始化flag
        this.setState(
        // eslint-disable-next-line no-shadow
          ({ remainingTime }) => (
            { remainingTime: remainingTime - 1, initialFlag: false }
          ),
        );
      }, 1000);
    } else {
      onResetLoading();
      // eslint-disable-next-line no-shadow
      this.setState(({ remainingTime }) => ({ remainingTime: REMAINING_TIME }));
    }
  }

  render() {
    const { isLoading } = this.props;
    const { remainingTime, initialFlag } = this.state;
    return (
      <Wrapper isLoading={isLoading} onClick={this.handleClick}>
        {
          // eslint-disable-next-line no-nested-ternary
          isLoading
            ? intl.formatMessage(messages.time, { time: remainingTime })
            : initialFlag
              ? intl.formatMessage(messages.text)
              : intl.formatMessage(messages.againText)
        }
      </Wrapper>
    );
  }
}

VerificationCode.propTypes = {
  onForgetPassword: PropTypes.func.isRequired,
  onResetLoading: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
};

function mapStateToProps(state) {
  return {
    isLoading: state.isVerificationCodeLoading,
  };
}

function mapDispatchToProps(dispatch, ownProps) {
  // const { history } = ownProps;
  return {
    onForgetPassword() {
      // history.push('/auc/password-forget');
      dispatch(fetchVerificationCode());
    },
    onResetLoading() {
      dispatch(setIsVerificationCodeLoading(false));
    },
  };
}

// export default withRouter(
//   connect(
//     null,
//     mapDispatchToProps,
//   )(
//     ForgetPassword,
//   ),
// );
export default connect(mapStateToProps, mapDispatchToProps)(VerificationCode);
