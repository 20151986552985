import React, { PureComponent } from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import intl from 'react-intl-universal';
import { defineMessages } from '#utils/i18n/intl';
import Tips from '#components/Tips';
import Center from '#components/layout/Center';
import Logo from '#components/Logo';
import { BigPromptTip } from '#components/PromptTip';
import Input from '#components/Input';
import Button from '#components/Button';
import VerificationCode from '#components/VerificationCode';
import MsgModal from '#components/MsgModal';
import Header from './components/Header';

import {
  handleFatalError,
  jumpToVerificationCodeHelpMessage,
  setBrandError,
  setForgetPasswordError,
  setForgetPasswordShowTips,
  setForgetPasswordTips,
  setNetworkError,
  setUsername,
  setVerificationCode,
  submitVerification,
} from '#models/login/action';

const messages = defineMessages({
  accountPlaceholder: {
    id: 'ForgetPassword.accountPlaceholder',
    defaultMessage: '手机号/邮箱',
  },
  validateCodePlaceholder: {
    id: 'ForgetPassword.validateCodePlaceholder',
    defaultMessage: '请输入您的验证码',
  },
  forgetPassword: {
    id: 'ForgetPassword.forgetPassword',
    defaultMessage: '找回密码',
  },
  verifyIdentity: {
    id: 'ForgetPassword.verifyIdentity',
    defaultMessage: '验证身份',
  },
  unreachableVerificationCode: {
    id: 'ForgetPassword.unreachableVerificationCode',
    defaultMessage: '没有收到验证码？',
  },
  askForHelp: {
    id: 'ForgetPassword.askForHelp',
    defaultMessage: '查看帮助',
  },
  helpMsgTitle: {
    id: 'ForgetPassword.helpMsgTitle',
    defaultMessage: '没有收到验证码怎么办？',
  },
  helpMsgLine_1: {
    id: 'ForgetPassword.helpMsgLine_1',
    defaultMessage: '亲爱的用户，验证码短信/邮件正常都会在数秒钟内发送，如果您未收到短信/邮件，请参照如下常见情况进行解决：',
  },
  helpMsgLine_2: {
    id: 'ForgetPassword.helpMsgLine_2',
    defaultMessage: '1、由于您的手机或邮箱软件设定了某些安全设置，验证码短信/邮件可能被拦截进了垃圾箱。请打开垃圾箱查看，并将HIK eSales号码添加为白名单。',
  },
  helpMsgLine_3: {
    id: 'ForgetPassword.helpMsgLine_3',
    defaultMessage: '2、由于运营商通道故障造成了短信/邮件发送时间延迟，请耐心稍候片刻或点击重新获取验证码。',
  },
  helpMsgLine_4: {
    id: 'ForgetPassword.helpMsgLine_4',
    defaultMessage: '3、关于手机号验证，目前支持移动、联通和电信的所有号码，暂不支持国际及港澳台地区号码。',
  },
  helpMsgLine_5_1: {
    id: 'ForgetPassword.helpMsgLine_5_1',
    defaultMessage: '如果您尝试了上述方式后均未解决，或存有其他疑问，请通过热线电话',
  },
  helpMsgLine_5_2: {
    id: 'ForgetPassword.helpMsgLine_5_2',
    defaultMessage: '获取客户协助。',
  },
});

const SignInTipWrapper = styled.div`
  margin: 0 0 8px;
`;

const HelpTipWrapper = styled.div`
  margin: 32px 0 0;
  text-align: center;
`;

const HelpTipFirst = styled.span`
  font-size: 12px;
  color: #a5a5a5;
`;

const HelpTipSecond = styled.span`
  font-size: 12px;
  color: #629DF9;
  cursor: pointer;
`;

const HelpMsgWrapper = styled.div`
  text-align: justify;
`;

const HelpMsgTitle = styled.h4`
  color: #525252;
`;

const HelpMsgLine = styled.p`
  font-size: 12px;
`;

const HelpMsgTel = styled.span`
  color: #2898E0;
`;

// const ForgetPassword = ({
//   onFetchVerificationCodeClick,
//   onVerificationClick,
//   onUsernameInput,
//   onVerificationCodeInput,
//   onPressEnter,
//   username,
//   verificationCode,
//   forgetPasswordError,
//   registerAddressError,
//   SSOError,
//   brandError,
//   networkError,
//   fatalError,
//   onCloseErrorModal,
// }) => (
//   <Center
//     showHeader
//     renderHeader={() => (<Header />)}
//   >
//     <SignInTipWrapper>
//       <BigPromptTip>(
//            <ErrorMsg>
//              没有收到验证码怎么办？
//            </ErrorMsg>
//          )
//         {intl.formatMessage(messages.forgetPassword)}
//       </BigPromptTip>
//     </SignInTipWrapper>
//     <Input
//       value={username}
//       placeholder={intl.formatMessage(messages.accountPlaceholder)}
//       onChange={onUsernameInput}
//       onKeyUp={onPressEnter}
//       type="text"
//       name="username"
//       data-ta-key="email_input"
//     >
//       <VerificationCode />
//     </Input>
//     <Input
//       value={verificationCode}
//       placeholder={intl.formatMessage(messages.validateCodePlaceholder)}
//       onChange={onVerificationCodeInput}
//       onKeyUp={onPressEnter}
//       type="text"
//       errorMsg={forgetPasswordError}
//       data-ta-key="password_input"
//     />
//     <Button
//       active={username && verificationCode}
//       onClick={onVerificationClick}
//       data-ta-key="login_btn"
//     >
//       {intl.formatMessage(messages.verifyIdentity)}
//     </Button>
//     <HelpTipWrapper>
//       <HelpTipFirst>
//         {intl.formatMessage(messages.unreachableVerificationCode)}
//       </HelpTipFirst>
//       <HelpTipSecond>
//         {intl.formatMessage(messages.askForHelp)}
//       </HelpTipSecond>
//     </HelpTipWrapper>
//     <AuthCode onClose={onFetchVerificationCodeClick} />
//     <MsgModal
//       show={
//         (!!SSOError)
//         || (!!brandError)
//         || (!!registerAddressError)
//         || (!!networkError)
//         || (!!fatalError)
//       }
//       errorMsg={
//         SSOError
//         || brandErrordispatch(setForgetPasswordErr
//         || registerAddrdispatch(setForgetPasswordErressError
//         || networkErrordispatch(setForgetPasswordErr
//         || fatalError
//       }
//       onClose={onCloseErrorModal}
//     />
//   </Center>
// );

function ForgetPassword({
  onVerificationClick,
  onUsernameInput,
  onVerificationCodeInput,
  onPressEnter,
  username,
  verificationCode,
  buttonLoading,
  showVerificationCodeHelpMessage,
  forgetPasswordError,
  registerAddressError,
  SSOError,
  brandError,
  networkError,
  fatalError,
  onCloseErrorModal,
  onOpenHelpModal,
  onCloseHelpModal,
  forgetPasswordTips,
  isLoading,
  clearTips,
}) {
  const HelpMsg = (
    <HelpMsgWrapper>
      <HelpMsgTitle>
        {intl.formatMessage(messages.helpMsgTitle)}
      </HelpMsgTitle>
      <HelpMsgLine>
        {intl.formatMessage(messages.helpMsgLine_1)}
      </HelpMsgLine>
      <HelpMsgLine>
        {intl.formatMessage(messages.helpMsgLine_2)}
      </HelpMsgLine>
      <HelpMsgLine>
        {intl.formatMessage(messages.helpMsgLine_3)}
      </HelpMsgLine>
      <HelpMsgLine>
        {intl.formatMessage(messages.helpMsgLine_4)}
      </HelpMsgLine>
      <HelpMsgLine>
        {intl.formatMessage(messages.helpMsgLine_5_1)}
        <HelpMsgTel> 4000-500-907 </HelpMsgTel>
        {intl.formatMessage(messages.helpMsgLine_5_2)}
      </HelpMsgLine>
    </HelpMsgWrapper>
  );

  return (
    <Center
      showHeader
      renderHeader={() => (<Header />)}
    >
      <SignInTipWrapper>
        <BigPromptTip>
          {intl.formatMessage(messages.forgetPassword)}
        </BigPromptTip>
      </SignInTipWrapper>
      <Input
        value={username}
        placeholder={intl.formatMessage(messages.accountPlaceholder)}
        onChange={onUsernameInput}
        onKeyUp={onPressEnter}
        type="text"
        name="username"
        data-ta-key="email_input"
      >
        <VerificationCode />
      </Input>
      <Input
        value={verificationCode}
        placeholder={intl.formatMessage(messages.validateCodePlaceholder)}
        onChange={onVerificationCodeInput}
        onKeyUp={onPressEnter}
        type="text"
        errorMsg={forgetPasswordError}
        data-ta-key="password_input"
      />
      <Button
        loading={buttonLoading}
        active={username && verificationCode}
        onClick={onVerificationClick}
        data-ta-key="login_btn"
      >
        {intl.formatMessage(messages.verifyIdentity)}
      </Button>
      <HelpTipWrapper>
        <HelpTipFirst>
          {intl.formatMessage(messages.unreachableVerificationCode)}
        </HelpTipFirst>
        <HelpTipSecond onClick={onOpenHelpModal}>
          {intl.formatMessage(messages.askForHelp)}
        </HelpTipSecond>
      </HelpTipWrapper>
      <MsgModal
        show={
          (!!SSOError)
          || (!!brandError)
          || (!!registerAddressError)
          || (!!networkError)
          || (!!fatalError)
        }
        msg={
          SSOError
          || brandError
          || registerAddressError
          || networkError
          || fatalError
        }
        onClose={onCloseErrorModal}
      />
      <MsgModal
        show={showVerificationCodeHelpMessage}
        msg={HelpMsg}
        onClose={onCloseHelpModal}
      />
      {isLoading && (<Tips closeable autoClose type="info" message={forgetPasswordTips} clearTips={clearTips} />)}
    </Center>
  );
}

ForgetPassword.propTypes = {
  onVerificationClick: PropTypes.func.isRequired,
  clearTips: PropTypes.func.isRequired,
  onUsernameInput: PropTypes.func.isRequired,
  onVerificationCodeInput: PropTypes.func.isRequired,
  onCloseErrorModal: PropTypes.func.isRequired,
  onOpenHelpModal: PropTypes.func.isRequired,
  onCloseHelpModal: PropTypes.func.isRequired,
  onPressEnter: PropTypes.func.isRequired,
  username: PropTypes.string.isRequired,
  verificationCode: PropTypes.string.isRequired,
  buttonLoading: PropTypes.bool.isRequired,
  showVerificationCodeHelpMessage: PropTypes.bool.isRequired,
  forgetPasswordError: PropTypes.string,
  registerAddressError: PropTypes.string,
  SSOError: PropTypes.string,
  brandError: PropTypes.string,
  networkError: PropTypes.string,
  fatalError: PropTypes.string,
  forgetPasswordTips: PropTypes.string,
  isLoading: PropTypes.bool,
};

ForgetPassword.defaultProps = {
  forgetPasswordError: null,
  registerAddressError: null,
  SSOError: null,
  brandError: null,
  networkError: null,
  fatalError: null,
  forgetPasswordTips: null,
  isLoading: false,
};

// connect组件
function mapStateToProps(state) {
  return {
    username: state.username,
    verificationCode: state.verificationCode,
    buttonLoading: state.forgetPasswordLoading,
    showVerificationCodeHelpMessage: state.showVerificationCodeHelpMessage,
    forgetPasswordError: state.forgetPasswordError,
    tenantError: state.tenantError,
    registerAddressError: state.registerAddressError,
    brandError: state.brandError,
    networkError: state.networkError,
    fatalError: state.fatalError,
    forgetPasswordTips: state.forgetPasswordTips,
    isLoading: state.forgetPasswordShowTips,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    onVerificationClick() {
      dispatch(submitVerification());
      dispatch(setForgetPasswordTips(null));
      dispatch(setForgetPasswordShowTips(false));
    },
    onUsernameInput(e) {
      dispatch(setUsername(e.target.value.trim()));
      dispatch(setForgetPasswordError(null));
    },
    onVerificationCodeInput(e) {
      dispatch(setVerificationCode(e.target.value.trim()));
      dispatch(setForgetPasswordError(null));
    },
    onCloseErrorModal() {
      dispatch(setBrandError(null));
      dispatch(setNetworkError(null));
      dispatch(handleFatalError());
    },
    onOpenHelpModal() {
      dispatch(jumpToVerificationCodeHelpMessage(true));
    },
    onCloseHelpModal() {
      dispatch(jumpToVerificationCodeHelpMessage(false));
    },
    onPressEnter(e) {
      if (e.keyCode === 13) {
        dispatch(submitVerification());
      }
    },
    clearTips() {
      dispatch(setForgetPasswordShowTips(false));
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ForgetPassword);
