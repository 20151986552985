import React from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import intl from 'react-intl-universal';
import { defineMessages } from '#utils/i18n/intl';
import MsgModal from '#components/MsgModal';
import { jumpToVerificationCodeHelpMessage } from '#models/login/action';

const HelpTipWrapper = styled.div`
margin: 32px 0 0;
text-align: center;
`;

const HelpTipFirst = styled.span`
font-size: 12px;
color: #a5a5a5;
`;

const HelpTipSecond = styled.span`
font-size: 12px;
color: #629DF9;
cursor: pointer;
`;

const HelpMsgWrapper = styled.div`
text-align: justify;
`;

const HelpMsgTitle = styled.h4`
color: #525252;
`;

const HelpMsgLine = styled.p`
font-size: 12px;
`;

const HelpMsgTel = styled.span`
color: #2898E0;
`;

const messages = defineMessages({
  unreachableVerificationCode: {
    id: 'ForgetPassword.unreachableVerificationCode',
    defaultMessage: '没有收到验证码？',
  },
  askForHelp: {
    id: 'ForgetPassword.askForHelp',
    defaultMessage: '查看帮助',
  },
  helpMsgTitle: {
    id: 'ForgetPassword.helpMsgTitle',
    defaultMessage: '没有收到验证码怎么办？',
  },
  helpMsgLine_1: {
    id: 'ForgetPassword.helpMsgLine_1',
    defaultMessage: '亲爱的用户，验证码短信/邮件正常都会在数秒钟内发送，如果您未收到短信/邮件，请参照如下常见情况进行解决：',
  },
  helpMsgLine_2: {
    id: 'ForgetPassword.helpMsgLine_2',
    defaultMessage: '1、由于您的手机或邮箱软件设定了某些安全设置，验证码短信/邮件可能被拦截进了垃圾箱。请打开垃圾箱查看，并将HIK eSales号码添加为白名单。',
  },
  helpMsgLine_3: {
    id: 'ForgetPassword.helpMsgLine_3',
    defaultMessage: '2、由于运营商通道故障造成了短信/邮件发送时间延迟，请耐心稍候片刻或点击重新获取验证码。',
  },
  helpMsgLine_4: {
    id: 'ForgetPassword.helpMsgLine_4',
    defaultMessage: '3、关于手机号验证，目前支持移动、联通和电信的所有号码，暂不支持国际及港澳台地区号码。',
  },
  helpMsgLine_5_1: {
    id: 'ForgetPassword.helpMsgLine_5_1',
    defaultMessage: '如果您尝试了上述方式后均未解决，或存有其他疑问，请通过热线电话',
  },
  helpMsgLine_5_2: {
    id: 'ForgetPassword.helpMsgLine_5_2',
    defaultMessage: '获取客户协助。',
  },
});

const ShowHelpMsg = ({
  showVerificationCodeHelpMessage,
  onOpenHelpModal,
  onCloseHelpModal,
}) => {
  const HelpMsg = (
    <HelpMsgWrapper>
      <HelpMsgTitle>
        {intl.formatMessage(messages.helpMsgTitle)}
      </HelpMsgTitle>
      <HelpMsgLine>
        {intl.formatMessage(messages.helpMsgLine_1)}
      </HelpMsgLine>
      <HelpMsgLine>
        {intl.formatMessage(messages.helpMsgLine_2)}
      </HelpMsgLine>
      <HelpMsgLine>
        {intl.formatMessage(messages.helpMsgLine_3)}
      </HelpMsgLine>
      <HelpMsgLine>
        {intl.formatMessage(messages.helpMsgLine_4)}
      </HelpMsgLine>
      <HelpMsgLine>
        {intl.formatMessage(messages.helpMsgLine_5_1)}
        <HelpMsgTel> 4000-500-907 </HelpMsgTel>
        {intl.formatMessage(messages.helpMsgLine_5_2)}
      </HelpMsgLine>
    </HelpMsgWrapper>
  );


  return (
    <>
      <HelpTipWrapper>
        <HelpTipFirst>
          {intl.formatMessage(messages.unreachableVerificationCode)}
        </HelpTipFirst>
        <HelpTipSecond onClick={onOpenHelpModal}>
          {intl.formatMessage(messages.askForHelp)}
        </HelpTipSecond>
      </HelpTipWrapper>
      <MsgModal
        show={showVerificationCodeHelpMessage}
        msg={HelpMsg}
        onClose={onCloseHelpModal}
      />
    </>
  );
};

ShowHelpMsg.propTypes = {
  onOpenHelpModal: PropTypes.func.isRequired,
  onCloseHelpModal: PropTypes.func.isRequired,
  showVerificationCodeHelpMessage: PropTypes.bool.isRequired,
};

function mapStateToProps(state) {
  return {
    showVerificationCodeHelpMessage: state.showVerificationCodeHelpMessage,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    onOpenHelpModal() {
      dispatch(jumpToVerificationCodeHelpMessage(true));
    },
    onCloseHelpModal() {
      dispatch(jumpToVerificationCodeHelpMessage(false));
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ShowHelpMsg);
