import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { connect } from 'react-redux';
// import { withRouter } from 'react-router-dom';
import intl from 'react-intl-universal';
import { defineMessages } from '#utils/i18n/intl';
import { jumpToForgetPassword } from '#models/login/action';

const messages = defineMessages({
  text: {
    id: 'ForgetPassword.text',
    defaultMessage: '忘记密码',
  },
});

const Wrapper = styled.span`
  font-size: 12px;
  color: #629df9;
  line-height: 18px;
  cursor: pointer;
`;

const ForgetPassword = ({ onForgetPassword }) => (
  <Wrapper onClick={onForgetPassword}>
    {intl.formatMessage(messages.text)}
  </Wrapper>
);

ForgetPassword.propTypes = {
  onForgetPassword: PropTypes.func.isRequired,
};

function mapDispatchToProps(dispatch, ownProps) {
  // const { history } = ownProps;
  return {
    onForgetPassword() {
      // history.push('/auc/password-forget');
      dispatch(jumpToForgetPassword());
    },
  };
}

// export default withRouter(
//   connect(
//     null,
//     mapDispatchToProps,
//   )(
//     ForgetPassword,
//   ),
// );
export default connect(null, mapDispatchToProps)(ForgetPassword);
