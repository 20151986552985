/* eslint-disable */
import React from 'react'
import axios from 'axios'
import queryString from 'query-string'
import Loading from '#static/img/txhyLoadingIcon.gif'
import styled from 'styled-components'
import * as dd from 'dingtalk-jsapi'
const IconWrapper = styled.img`
	display: block;
	margin: 100px auto;
`
export default class WemeetMain extends React.PureComponent {
	constructor(props) {
		super(props)
		console.log('dingtalk-props:', this.props)
		this.state = {}
	}
	componentDidMount() {
		const {
			corpId,
			neoReturnUri,
			neoClient = '',
			neoType = 'dingtalk',
			agentId,
		} = queryString.parse(window.location.search)
		document.title = 'HIK eSales'
		let encode_url
		if (neoReturnUri) {
			encode_url = encodeURIComponent(neoReturnUri)
		}
		console.log(corpId, neoReturnUri, encode_url)
		this.verifiAuthCode({ corpId, encode_url, neoClient, neoType, agentId })
	}
	verifiAuthCode = (params) => {
		const { corpId } = params
		const _this = this
		console.log('dd.env.platform', dd.env.platform, params)
		if (corpId && dd.env.platform !== 'notInDingTalk') {
			dd.ready(function() {
				console.log('dd.ready')
				// dd.ready参数为回调函数，在环境准备就绪时触发，jsapi的调用需要保证在该回调函数触发后调用，否则无效。
				dd.runtime.permission.requestAuthCode({
					corpId: corpId,
					onSuccess: function(result) {
						console.log('dd.onSuccess', result)
						_this.gotoAuc(params, result.code)
					},
					onFail: function(err) {
						console.error('dd.onFail', err)
						_this.gotoErrorPage(err)
					},
				})
			})
		} else {
			console.error('notInDingTalk || not corpId')
			this.gotoErrorPage(null)
		}
	}
	gotoErrorPage = (error) => {
		//此为后端提供的一个错误页
		const errorHref = `${window.loginDomainDtalk}/auc/sso-error?code=900000`
		window.location.href = errorHref
	}
	gotoAuc = (params, code) => {
		try {
			const { corpId, encode_url, neoClient, neoType, agentId } = params
			if (!encode_url) {
				encode_url = encodeURIComponent(
					`/bff/spa/neo-platform-ui-h5/index.html#/home&platform=H5&deviceType=0`
				)
			}
			const href = `${window.loginDomainDtalk}/auc/third/auth?neoType=${neoType}&neoReturnUri=${encode_url}&corpId=${corpId}&agentId=${agentId}&code=${code}`
			console.log('dd.href', window.location.host, href)
			window.location.href = href
		} catch (error) {
			console.error('dd.auc params error:', error)
			this.gotoErrorPage(error)
		}
	}
	render() {
		return (
			<div>
				<IconWrapper src={Loading} />
			</div>
		)
	}
}
