/* eslint-disable */
import React from "react";
import { defineMessages } from '#utils/i18n/intl';
import styled from "styled-components";
import queryString from 'query-string';
import ssoErrorImg from "#static/img/txhy-error.png";
import SuccessIconSrc from '#static/img/txhy-success.png';

const messages = defineMessages({
  errorMsg: {
    id: 'SSOError.errorMsg',
    defaultMessage: '系统登录异常，请稍后重试或与管理员联系。',
  },
});
const Container = styled.div`
  display: flex;
  height: 100vh;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  img {
    width: 320px;
    margin-bottom: 20px;
  }
`;
const ResultText = styled.div`
  text-align: center;
  color: #71747A;
  font-size: 15px;
  .ResultTextTitle{
    margin: 40px 0 20px 0;
    height:24px;
    line-height: 24px;
    font-size:28px;
    color:#404752;
  }
  .ResultTextCons{
    color:#63686F;
    font-size:14px;
    margin:0;
  }
  .goBack{
    color:#1D77FF;
    font-size:14px;
    margin:0;
    cursor:pointer;
  }

`;
const IconWrapper = styled.img`
  display: block;
  margin: 0 auto 24px;
`;
export default class WemeetResult extends React.PureComponent {
  componentDidMount() {
    document.title = "HIK eSales";
    this.getResule()
    var _this = this;
    setTimeout(function(){
      console.log('1111')
      var result = _this.getCookie('wemeetResult')
      console.log(result)
    },10000)
  }
  getResule(){
    const { code } = queryString.parse(window.location.search); 
    if(code == 200){
      this.setCookie('wemeetResult','wemeetResult',1)
      // setTimeout(function(){
      //   var userAgent = navigator.userAgent
      //   if (userAgent.indexOf('Firefox') !== -1 || userAgent.indexOf('Chrome') !== -1) {
      //     window.location.replace('about:blank')
      //   } else {
      //     window.opener = null
      //     window.open('', '_self')
      //   }
      //   window.close()
      // },5000)
      
    }
  }
  goBack(){
    var userAgent = navigator.userAgent
    if (userAgent.indexOf('Firefox') !== -1 || userAgent.indexOf('Chrome') !== -1) {
      window.location.replace('about:blank')
    } else {
      window.opener = null
      window.open('', '_self')
    }
    window.close()
  }
  setCookie(cname,cvalue,exdays){
    var d = new Date();
    d.setTime(d.getTime()+(exdays*24*60*60*1000));
    var expires = "expires="+d.toGMTString();
    var doMain = "Domain=.xiaoshouyi.com"
    document.cookie = cname+"="+cvalue+"; "+expires +";"+ doMain;
  }
  getCookie(cname){
    var name = cname + "=";
    var ca = document.cookie.split(';');
    for(var i=0; i<ca.length; i++) {
      var c = ca[i].trim();
      if (c.indexOf(name)==0) { return c.substring(name.length,c.length); }
    }
    return "";
  }
  render() {
    const { code,msg } = queryString.parse(window.location.search); 

    let errorMsg = msg
    if(code == 2031042){
      errorMsg = '您的腾讯会议企业已绑定其他HIK eSales租户'
    }else if(code == 2031041){
      errorMsg = '请使用腾讯会议企业版或商业版进行绑定,详情咨询可拨打电话: 4009100100转5'
    }
    // 根据返回的code 转换成对应的错误信息
    return (
      <Container>
        {
          code != 200 &&  
          <div>
            <IconWrapper src={ssoErrorImg} />
            <p className=""></p>
            <ResultText>
              <p className="ResultTextTitle">绑定失败！</p>
              <p className="ResultTextCons">失败原因：{errorMsg}</p>
            </ResultText>
          </div>
        }
        {
          code == 200 && 
          <div>
            <IconWrapper src={SuccessIconSrc} />
            <p className=""></p>
            <ResultText>
              <p className="ResultTextTitle">绑定成功！</p>
              <span className="goBack" onClick={() => this.goBack()}>返回HIK eSalesCRM</span>
            </ResultText>
          </div>
        }
       
      </Container>
    );
  }
}

