/* eslint-disable */
import {
  call,
  put
} from "redux-saga/effects";
import intl from "react-intl-universal";
import getScodeMessages from "#utils/i18n/getScodeMessages";
import {
  ResetPasswordType,
  setPassport,
  setPassportError,
  setTenantError,
  setNECaptchaValidate,
  createTenantList,
  submitTenant,
  jumpToSelectTenant,
  jumpToResetPassword,
  setLoginLoading
} from "../action";
import {
  getPassportService,
  status
} from "../LoginService";
import {
  store
} from "../index";

export default function* fetchPassport() {
  const state = store.getState();
  const {
    username,
    password,
    NECaptchaValidate,
    mobileParams
  } = state;
  try {
    const res = yield call(getPassportService, {
      username,
      password,
      NECaptchaValidate,
      mobileParams
    });
    console.log('res', res)
    // 清空NECaptchaValidate
    // 保证只有call(popupAuthCode)时才会获取最新的NECaptchaValidate
    // 其他请求不带有NECaptchaValidate
    yield put(setNECaptchaValidate(null));
    if (!res) {
      yield put(setLoginLoading(false));
      return;
    }
    switch (res.scode) {
      case status.OK:
        // 登录请求成功
        // 1、设置passport信息
        // 2、清空username和password的错误信息
        // 3、设置租户列表
        yield put(setPassport(res.result.passport_id));
        yield put(setPassportError(null));
        yield put(setTenantError(null));
        yield put(createTenantList(res.result.tenant_list));
        // 记录最后一次登陆的用户名
        localStorage.setItem("lastLoginUserId", username);
        console.log(res.result, '租户列表的数量');
        // 4、如果租户数量超过1个，则跳转到选择租户页面，否则直接登录
        const tenantsNumber = res.result.tenant_list.length;
        if (tenantsNumber === 1) {
          yield put(submitTenant(res.result.tenant_list[0].id));
        } else if (tenantsNumber > 1) {
          yield put(jumpToSelectTenant());
          yield put(setLoginLoading(false));
        } else {
          console.error("There's no tenants for this passport.");
          yield put(setLoginLoading(false));
        }
        break;
      case status.FIRST_LOGIN:
        // 用户首次登录需要跳转到重置密码页面：
        // 1、设置passport信息
        // 2、开启重置密码的一系列流程
        yield put(setPassport(res.result.passport_id));
        yield put(
          jumpToResetPassword({
            resetPasswordFlag: ResetPasswordType.FIRST_LOGIN
          })
        );
        yield put(setLoginLoading(false));
        break;
      case status.RESET_PASSWORD_1:
      case status.RESET_PASSWORD_2:
      case status.RESET_PASSWORD_3:
      case status.RESET_PASSWORD_4:
      case status.RESET_PASSWORD_5:
      case status.RESET_PASSWORD_6:
        yield put(setPassport(res.result.passport_id));
        // 用户密码规则修改，需要跳转到重置密码页面：
        // 开启重置密码的一系列流程
        yield put(
          jumpToResetPassword({
            resetPasswordFlag: ResetPasswordType.RULES_CHANGED
          })
        );
        yield put(setLoginLoading(false));
        break;
      case status.PASSWORD_EXPIRED:
        yield put(setPassport(res.result.passport_id));
        yield put(
          jumpToResetPassword({
            resetPasswordFlag: ResetPasswordType.PASSWORD_EXPIRED
          })
        );
        yield put(setLoginLoading(false));
        break;
      default:
        // 登录请求失败
        // 1、设置passportError请求错误信息
        yield put(
          setPassportError(
            intl.formatMessage(getScodeMessages(res.scode), {
              errorMsg: res.error_msg
            })
          )
        );
        yield put(setLoginLoading(false));
        break;
    }
  } catch (error) {
    yield put(setLoginLoading(false));
  }
}