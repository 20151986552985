/* eslint-disable */
import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Route, Redirect, Switch } from 'react-router-dom';
// import './normalize.css';
import './App.css';
import { loginStates, startInit } from '#models/login/action';
import Container from '#components/layout/Container';
import Login from '#pages/Login';
import ForgetPassword from '#pages/ForgetPassword';
import ResetPassword from '#pages/ResetPassword';
import ResetPasswordSuccessfully from '#pages/ResetPasswordSuccessfully';
import SelectTenant from '#pages/SelectTenant';
import Authentication from '#pages/Authentication'
import QYWXTenant from '#components/qywxTenant';
import PassLogin from "#components/passLogin"
import Error404 from '#pages/Error404';
import PodRelease from '#pages/PodRelease';
import CancelBounceEffect from '#utils/cancelBounceEfffect';
import { get } from '#utils/globalVariable';
import queryString from "query-string";



const {
  SELECT_TENANT,
  RESET_PASSWORD,
  RESET_PASSWORD_SUCCESSFULLY,
  AUTHENTICATION,
} = loginStates;

const switchStatus = defaultStatus => (status) => {
  console.log('status', status);
  switch (status) {
    // case LOGINING:
    //   return <Login />;module
    case RESET_PASSWORD:
      return <ResetPassword />;
    case RESET_PASSWORD_SUCCESSFULLY:
      return <ResetPasswordSuccessfully />;
    case SELECT_TENANT:
      return <SelectTenant />;
    case AUTHENTICATION:
      return <Authentication />
    default:
      return defaultStatus;
  }
};
// const isParams=(val)=> {
//   if (val === undefined || val === null || val === "") {
//     console.log(val,":11111111")
//     return val = 1
//   } else if (val && val === 0) {
//     console.log(val,"222222")
//     return val = val
//   } else if (val && val === 1) {
//     // 默认值
//     return val = val
//   }
// }
// 短信验证码登录
const { smsenabled, passwordenabled, ssoenabled } = queryString.parse(window.location.search);
// 当短信的值为0的时候显示默认登录页面
console.log(smsenabled,smsenabled==0,"kkkkkkk")
// const switchLoginStatus = smsenabled==1?switchStatus(<Tab />):switchStatus(<Login />);
const switchLoginStatus = smsenabled==1?switchStatus(<PassLogin />):switchStatus(<Login />);
// const switchLoginStatus =switchStatus(<Login />);
const switchForgetPasswordStatus = switchStatus(<ForgetPassword />);

export class App extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {};
    this.cancel = null;
  }

  componentDidMount() {
    const { onStartInit } = this.props;
    onStartInit();
    // 取消IOS平台的橡皮筋效果
    this.cancel = new CancelBounceEffect();
    this.cancel.start();
    get('env') === 'WEB' && this.setVersionStateCookie('v2304');
  }

  componentWillUnmount() {
    this.cancel.destroy();
  }

  setVersionStateCookie = (version) => {
    const expiresDate = new Date();
    const EXPIRE_AT_7_DAYS_LATER = 7;
    expiresDate.setDate(expiresDate.getDate() + EXPIRE_AT_7_DAYS_LATER);
    const expiresStr = expiresDate.toGMTString();
    document.cookie = `${version}LoginState=true;expires=${expiresStr};path=/;domain=.xiaoshouyi.com`;
  };

  render() {
    const { status, hasInitialized } = this.props;
    const env = get('env');
    console.log(env)
    switch (env) {
      case 'WEB':
      case 'MOBILE':
        return (
          hasInitialized
            ? (
              <Container status={status}>
                <Switch>
                  <Route path="/auc/wxwork/multi-tenant" exact render={() => QYWXTenant}></Route>
                  <Route path="/auc/password-forget" exact render={() => switchForgetPasswordStatus(status)} />
                  <Route path={['/auc/oauth2/auth', '/']} exact render={() => switchLoginStatus(status)} />
                  {/* 短信验证码组件 */}
                  <Route path="/auc/password-forget" exact render={() => switchForgetPasswordStatus(status)} />
                  <Route component={Error404} />
                </Switch>
              </Container>
            )
            : null
        );
      case 'ERROR':
        return (
          <Container status={status}>
            <Error404 />
          </Container>
        );
      case 'PODRELEASE':
        return (
          <Container status={status}>
            <PodRelease />
          </Container>
        );
      default:
        return null;
    }
  }
}

App.propTypes = {
  status: PropTypes.oneOf([
    null,
    RESET_PASSWORD,
    RESET_PASSWORD_SUCCESSFULLY,
    SELECT_TENANT,
    AUTHENTICATION,
  ]),
  hasInitialized: PropTypes.bool.isRequired,
  onStartInit: PropTypes.func.isRequired,
};

App.defaultProps = {
  status: null,
};

// connet组件
function mapStateToProps(state) {
  return {
    status: state.loginState,
    hasInitialized: state.hasInitialized,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    onStartInit() {
      dispatch(startInit());
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(App);
