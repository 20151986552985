/* eslint-disable */
import React from "react";
import styled from "styled-components";
import error from "#static/img/wxwork-error.png";
import queryString from 'query-string';

const NO_CORPID_CODE = '2030074';
export default class WxworkError extends React.PureComponent {
  componentDidMount() {
    document.title = "HIK eSales";
  }

  render() {
    const { error_code } = queryString.parse(window.location.search);
    const msg = String(error_code) === NO_CORPID_CODE ? '您的企业与CRM租户绑定关系已解除，请联系管理员恢复绑定' : '请稍后重试，老客户可联系管理员进行账号绑定';
    return (
      <Container>
        <img src={error} />
        {msg}
      </Container>
    );
  }
}

const Container = styled.div`
  height: 100vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  color: #444d5c;
  font-size: 14px;
  padding-top: 200px;
  padding-left: 30px;
  padding-right: 30px;
  box-sizing: border-box;

  img {
    height: 160px;
    margin-bottom: 20px;
  }
`;
