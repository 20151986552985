/* eslint-disable */
import React from "react";
import styled from "styled-components";
import Input from "#components/Input";
import { Service } from '#models/Service';
import intl from 'react-intl-universal';
import getScodeMessages from '#utils/i18n/getScodeMessages';

const SubmitInput = styled.span`
  display: ${(props) => (props.show ? "block" : "none")};
`;
const Wrapper = styled.span`
  font-size: 12px;
  color: ${(props) => (props.isLoading ? "#6A7280" : "#629DF9")};
  line-height: 18px;
  cursor: pointer;
`;

export default class extends React.Component {
  constructor(props) {
    super(props);
    this.inputRef = React.createRef();
    this.timer = null;
  }
  state = {
    times: 0,
    isFirstDom: this.props.type == 'firstChild',
  };
  componentDidMount() {
    const { isFirstDom } = this.state;
    isFirstDom && this.setTimeHandle();
  }

  setTimeHandle = async () => {
    const { times } = this.state;
    if (times > 0) {
      return;
    }
    const res = await this.sendCode();
    if (res === "error") {
      return;
    }
    this.setState({ times: 60 });
    this.setIntervalFn();
  };

  setIntervalFn = () => {
    this.timer = setInterval(() => {
      const { times } = this.state;
      if (times <= 0) {
        clearTimeout(this.timer);
      } else {
        this.setState({
          times: times - 1,
        });
      }
    }, 1000);
  };
  sendCode = async () => {
    const { request } = this.props
    const { setVerifyCodeErrorHandle,currentUser,userInfo} = request;
    try {
      const res = await Service.post('/auc/mfa/captcha', {
        login_name: currentUser,
        passport_id: userInfo.passportId,
        tenant_id: userInfo.tenantDetail.tenantId,
      });
      if (res.scode !== '200') {
        const errorMsg = intl.formatMessage(getScodeMessages(res.scode || 1000000));
        if (errorMsg) {
          setVerifyCodeErrorHandle(errorMsg);
        }
        return 'error';
      }
    } catch (err) {
      console.log(err);
      return 'error';
    }
    return true;
  };

  render() {
    const {
      codeValue,
      verifyCodeError,
      codePlaceholder,
      onChange,
      onFocus,
      message,
      type,
    } = this.props;
    const { times,isFirstDom } = this.state;
    // console.log(object);
    return (
      <SubmitInput className={type} show={isFirstDom}>
        <Input
          value={codeValue}
          errorMsg={verifyCodeError}
          placeholder={codePlaceholder}
          onChange={onChange}
          onFocus={onFocus}
          type="text"
          name="authentication"
          data-ta-key="code_input"
        >
          {
            <Wrapper isLoading={times > 0} onClick={this.setTimeHandle}>
              {times > 0 ? times : message}
              {times > 0 ? "s" : ""}
            </Wrapper>
          }
        </Input>
      </SubmitInput>
    );
  }
}
