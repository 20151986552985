import { createSelector } from 'reselect';
// import brand1 from '#static/img/login-crm.png';
import prmBrand1 from '#static/img/login-prm-pic1.png';
import prmBrand1En from '#static/img/login-prm-pic1-en.png';
import prmBrand2 from '#static/img/login-prm-pic2.png';
import prmBrand2En from '#static/img/login-prm-pic2-en.png';
import prmBrand3 from '#static/img/login-prm-pic3.png';
import prmBrand3En from '#static/img/login-prm-pic3-en.png';
import loginRotation0 from '#static/img/loginRotation0.png';
import loginRotation1 from '#static/img/loginRotation1.png';
import loginRotation2 from '#static/img/loginRotation2.png';
import loginRotation3 from '#static/img/loginRotation3.png';
import loginRotation4 from '#static/img/loginRotation4.png';
import loginRotation5 from '#static/img/loginRotation0_1.png';
import loginRotation6 from '#static/img/loginRotation1_1.png';
import loginRotation7 from '#static/img/loginRotation2_1.png';
import loginRotation8 from '#static/img/loginRotation3_1.png';
import loginRotation9 from '#static/img/loginRotation4_1.png';

const getBrandPictures = state => state.brandInfo.pictures;
const getSubDomain = state => state.subDomain;
const getScope = state => state.scope;

const isZhCN = navigator.language.indexOf('zh') !== -1;

const imgLinkUrl = () => {
  return [
    {
      picture_url: loginRotation0,
    }];
  console.log('本地的参数', navigator.language);
  /*
  if (isZhCN) {
    return [
      {
        picture_url: loginRotation0,
        element_refer_url: 'https://www.xiaoshouyi.com/paas',
      },
      {
        picture_url: loginRotation1,
        element_refer_url: 'https://www.xiaoshouyi.com/newnew/88745.html',
      },
      {
        picture_url: loginRotation2,
        element_refer_url: 'https://www.xiaoshouyi.com/fxy',
      },
      {
        picture_url: loginRotation3,
        element_refer_url: 'https://www.xiaoshouyi.com/cdp',
      },
      {
        picture_url: loginRotation4,
        element_refer_url: 'https://www.xiaoshouyi.com/88399-2',
      },
    ];
  }
  return [
    {
      picture_url: loginRotation5,
      element_refer_url: 'https://www.neocrm.com/en/',
    },
    {
      picture_url: loginRotation6,
      element_refer_url: 'https://www.neocrm.com/en/',
    },
    {
      picture_url: loginRotation7,
      element_refer_url: 'https://www.neocrm.com/en/',
    },
    {
      picture_url: loginRotation8,
      element_refer_url: ' https://www.neocrm.com/en/',
    },
    {
      picture_url: loginRotation9,
      element_refer_url: ' https://www.neocrm.com/en/',
    },
  ];
  */
};

const imgPrmLinkUrl = () => {
  if (isZhCN) {
    return [
      {
        picture_url: prmBrand1,
        element_refer_url: 'https://www.xiaoshouyi.com/prm',
      },
      {
        picture_url: prmBrand2,
        element_refer_url: 'https://www.xiaoshouyi.com/prm',
      },
      {
        picture_url: prmBrand3,
        element_refer_url: 'https://www.xiaoshouyi.com/prm',
      },
    ];
  }
  return [
    {
      picture_url: prmBrand1En,
      element_refer_url: 'https://www.neocrm.com/en/prm',
    },
    {
      picture_url: prmBrand2En,
      element_refer_url: 'https://www.neocrm.com/en/prm',
    },
    {
      picture_url: prmBrand3En,
      element_refer_url: 'https://www.neocrm.com/en/prm',
    },
  ];
};
const getDefault = scope => (scope === 'prm' ? imgPrmLinkUrl() : imgLinkUrl());

export default createSelector(
  getBrandPictures,
  getSubDomain,
  getScope,
  // eslint-disable-next-line no-nested-ternary
  (pictures, subDomain, scope) => {
    // console.log(pictures, subDomain, scope);
    return (subDomain && pictures.length > 0 ? pictures : getDefault(scope));
  },
);
