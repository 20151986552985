/* eslint-disable */
import React from "react";
import styled from "styled-components";
import ssoErrorImg from "#static/img/sso_error.png";
import intl from 'react-intl-universal';
import { defineMessages } from '#utils/i18n/intl';
import queryString from 'query-string';
import getScodeMessages from "#utils/i18n/getScodeMessages";

const messages = defineMessages({
  errorMsg: {
    id: 'SSOError.errorMsg',
    defaultMessage: '系统登录异常，请稍后重试或与管理员联系。',
  },
});

const Container = styled.div`
  display: flex;
  height: 100vh;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  img {
    width: 320px;
    margin-bottom: 20px;
  }
`;
const ErrorMessage = styled.div`
  text-align: center;
  color: #71747A;
  font-size: 15px;
  p {
    margin: 0;
    line-height: 24px;
  }
`;
export default class SSOError extends React.PureComponent {
  componentDidMount() {
    document.title = "HIK eSales";
  }

  render() {
    const { code } = queryString.parse(window.location.search); 
    // 根据返回的code 转换成对应的错误信息
    let codeMessage = intl.formatMessage(getScodeMessages(code))
    return (
      <Container>
        <img src={ssoErrorImg} />
        <ErrorMessage>
          <p>{intl.formatMessage(messages.errorMsg)}</p>
          {
            code && code != 0 ?
            <p>error : {code}({codeMessage})</p> : null
          }
        </ErrorMessage>
      </Container>
    );
  }
}

