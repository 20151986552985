// src/components/ExternalLogin.jsx


/* eslint-disable */
import React from "react";
import styled from "styled-components";
import queryString from "query-string";
import { connect } from "react-redux";
import intl from "react-intl-universal";
import { status } from "#models/login/LoginService";
import { Service } from "#models/Service";
import qywxLogo from "#static/img/login-qywx.png";
import txyLogo from "#static/img/login-txy.png";
import samlLogo from "#static/img/saml.png";
import { defineMessages } from "#utils/i18n/intl";
import { get } from "#utils/globalVariable";
import getScodeMessages from "#utils/i18n/getScodeMessages";
import { setGlobalError } from "#models/login/action";

const messages = defineMessages({
  externalLoginTitle: {
    id: "Login.externalLoginTitle",
    defaultMessage: "其他方式登录",
  },
  loginWithQYWX: {
    id: "Login.loginWithQYWX",
    defaultMessage: "企业微信登录",
  },
  loginWithTencentCloud: {
    id: "Login.loginWithTencentCloud",
    defaultMessage: "腾讯云登录",
  },
  loginSingle: {
    id: "Login.loginSingle",
    defaultMessage: "单点登录",
  }
});

const LOGIN_TYPE = {
  manual: 0,
  auto: 1,
};

const WrapperDiv = styled.div`
  width: 100%;
  margin-top: 24px;

  .external-login {
    &__title {
      text-align: center;
      position: relative;
      font-size: 12px;
      color: #a5a5a5;
      width: 100%;
      margin-bottom: 24px;

      &:after,
      &:before {
        content: "";
        position: absolute;
        top: 50%;
        background: #dfe3eb;
        height: 1px;
        width: 36%;
      }

      &:after {
        left: 0;
      }

      &:before {
        right: 0;
      }
    }

    &__links {
      display: flex;
      justify-content: center;
      align-items: center;
    }

    &__link {
      display: flex;
      flex-direction: column;
      align-items: center;
      cursor: pointer;

      &__logo {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 44px;
        height: 44px;
        border: 1px solid rgba(25, 31, 37, 0.08);
        border-radius: 50%;
        margin-bottom: 8px;

        img {
          width: 32px;
        }
      }

      &__text {
        font-size: 12px;
        color: #a5a5a5;
      }

      &:not(:last-child) {
        margin-right: 18px;
      }
    }
  }
`;
const GeneralLogin = styled.div`
`;

class ExternalLogin extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      qywx: "",
      tencentCloud: "",
      saml: "",
      samlName: "",
      oauth2Name: "",
      oauth2: "",
      qiyeweixin: "",
      qiyeweixinName: "",
      ssoList:[]
    };
  }

  fetchExternalLogin = async () => {
    const {
      ssoKey,
      sub_domain: subDomain = "",
      error_code,
      return_uri,
      mobile_params,
    } = queryString.parse(window.location.search);
    const { setGlobalError } = this.props;
    const env = get("env");
    const reqData = {
      ssoType: "",
      subDomain,
      deviceType: env.toLowerCase(),
    };
    if (!!return_uri) {
      reqData["return_uri"] = return_uri;
    }
    if (!!ssoKey && ssoKey !== "null") {
      reqData["ssoKey"] = ssoKey;
    }
    const res = await Service.post("/auc/sso/multi/3rd/idp", reqData);
    const { scode, result = [] } = res;
    if (scode === status.OK && result.length) {
      if (mobile_params) {
        result.forEach((item) => {
          const { authenticationUri } = item;
          const mark = authenticationUri.indexOf("?") === -1 ? "?" : "&";
          item.authenticationUri = `${authenticationUri}${mark}mobile_params=${encodeURIComponent(
            mobile_params
          )}`;
        });
      }
 
      const autoLoginItem = result[0];
      if (autoLoginItem.autoLogin && !error_code) {
        if (env === "MOBILE" && autoLoginItem.ssoType === "qiyeweixin") {
          const agent = navigator.userAgent.toLowerCase();
          if (agent.indexOf("android") !== -1) {
            if(window.postMessage){
              window.bridge.postMessage(
                JSON.stringify({
                  action: "openOtherApp"
                })
              );
            }
            if(window.xsyPostMessage){
              window.bridge.xsyPostMessage(
                JSON.stringify({
                  action: "openOtherApp"
                })
              );
            }
          } else if (agent.indexOf("iphone") !== -1) {
            if(window.webkit.messageHandlers.openOtherApp.postMessage){
              window.webkit.messageHandlers.openOtherApp.postMessage(1);
            }
            if(window.webkit.messageHandlers.openOtherApp.xsyPostMessage){
              window.webkit.messageHandlers.openOtherApp.xsyPostMessage(1);
            }
          }
        } else {
          const { authenticationUri } = autoLoginItem;
          window.location.assign(authenticationUri);
        }
      } else {
        let ssoList = res.result;
        ssoList.forEach((item) => {
          //const { authenticationUri, name: samlName } = item;
          const { authenticationUri, name } = item;
          item.ssoType === "saml" &&
            this.setState({
              saml: authenticationUri,
              samlName: name,
            });
          item.ssoType === "qywx" &&
            this.setState({ qywx: item.authenticationUri });
          item.ssoType === "oauth2" &&
            this.setState({ oauth2: item.authenticationUri, oauth2Name:name });
          // item.ssoType === "tencentCloud" &&
          //   this.setState({ tencentCloud: item.authenticationUri });
          item.ssoType === "qiyeweixin" &&
            this.setState({ qiyeweixin: item.authenticationUri, qiyeweixinName: item.name });
        });
        this.setState({ssoList})
      }
    } else {
      // 7月4日，移动端ssoKey传递有误，暂时禁用错误提示
       setGlobalError(intl.formatMessage(getScodeMessages(res.scode)));
    }
  };

  componentDidMount() {
    const {ssoenabled } = queryString.parse(window.location.search);
    if(ssoenabled==1||ssoenabled===undefined) {
      this.fetchExternalLogin();
    }else {
      return;
    }
  }
  otherApp = () => {
    const agent = navigator.userAgent.toLowerCase();
    if (agent.indexOf("android") !== -1) {
      if(window.bridge.postMessage){
        window.bridge.postMessage(
          JSON.stringify({
            action: "openOtherApp"
          })
        );
      }
      if(window.bridge.xsyPostMessage){
        window.bridge.xsyPostMessage(
          JSON.stringify({
            action: "openOtherApp"
          })
        );
      }
    } else if (agent.indexOf("iphone") !== -1) {
      if(window.webkit.messageHandlers.openOtherApp.postMessage){
        window.webkit.messageHandlers.openOtherApp.postMessage(1);
      }
      if(window.webkit.messageHandlers.openOtherApp.xsyPostMessage){
        window.webkit.messageHandlers.openOtherApp.xsyPostMessage(1);
      }
    }
  }

  navigateToExternalUrl = (e) => {
    let env = get("env");
    const {url, type} = e.currentTarget.dataset
    if (env === "MOBILE"&&type === "qiyeweixin") {
      this.otherApp()
    } else {

      window.location.assign(url);
    }
  };

  render() {
    const {ssoenabled } = queryString.parse(window.location.search);
    const { scope } = this.props;
    //if (scope === "prm") return null;
    const { qywx, tencentCloud, saml, samlName, oauth2, qiyeweixin, qiyeweixinName, oauth2Name, ssoList } = this.state;
    if (
      (!qywx && !tencentCloud && !saml && !oauth2 && !qiyeweixin) ||
      (get("env") === "MOBILE" && !saml && !oauth2 && !qiyeweixin)
    ) {
      return null;
    }
    return (
      <WrapperDiv>
        {ssoenabled==1||ssoenabled===undefined ?
        <div className="external-login">
          {/*<div className="external-login__title">*/}
          {/*  {intl.formatHTMLMessage(messages.externalLoginTitle)}*/}
          {/*</div>*/}
          <GeneralLogin style={{ display: 'none' }}>
            <div className="external-login__links">
              {
                ssoList.map((sso, i)=>{
                  return(
                      <div
                          key={i}
                          className="external-login__link"
                          data-type={sso.ssoType}
                          data-url={sso.authenticationUri}
                          onClick={this.navigateToExternalUrl}
                      >
                        {sso.ssoType === 'saml' && <div className="external-login__link__logo"><img src={samlLogo}/></div>}
                        {sso.ssoType === 'qywx' && scope !== "prm" && <div className="external-login__link__logo"><img src={qywxLogo}/></div>}
                        {sso.ssoType === 'oauth2' && <div className="external-login__link__logo"><img src={samlLogo}/></div>}
                        {sso.ssoType === 'qiyeweixin' && <div className="external-login__link__logo"><img src={qywxLogo}/></div>}
                        { <div className="external-login__link__text" hidden={scope === "prm" && sso.ssoType === 'qywx'}>{sso.name}</div>}
                      </div>
                  )
                })
              }
              {/* {tencentCloud && (
                <div
                  className="external-login__link"
                  onClick={this.navigateToExternalUrl.bind(this, "tencentCloud")}
                >
                  <div className="external-login__link__logo">
                    <img src={txyLogo} />
                  </div>
                  <div className="external-login__link__text">
                    {intl.formatHTMLMessage(messages.loginWithTencentCloud)}
                  </div>
                </div>
              )} */}

            </div>
          </GeneralLogin>
        </div>
  :'' }
      </WrapperDiv>
    );
  }
}

export default connect(
  (state) => ({
    scope: state.scope,
  }),
  (dispatch) => ({ setGlobalError: (msg) => dispatch(setGlobalError(msg)) })
)(ExternalLogin);
