/* eslint-disable */
import BrowserModal from '#components/BrowserModal';
import Button from '#components/Button';
import ExternalLogin from '#components/ExternalLogin';
import ForgetPassword from '#components/ForgetPassword';
import withTips from '#components/HOC/WithTips';
import Input from '#components/Input';
import Logo from '#components/Logo';
import MsgModal from '#components/MsgModal';
import { BigPromptTip } from '#components/PromptTip';
import RegisterAndPrivacy from '#components/RegisterAndPrivacy';
import Center from '#components/layout/Center';
import { get } from '#utils/globalVariable';
import { defineMessages } from '#utils/i18n/intl';
// import Scada from '#utils/sentry/index';
import PropTypes from 'prop-types';
import React from 'react';
import intl from 'react-intl-universal';
import { connect } from 'react-redux';
import styled from 'styled-components';
import AvatarMonitor from '#utils/sentry/AvatarMonitor';


// import SSO from '#components/SSO';


import {
  clearUserName,
  handleFatalError,
  protocalChanged,
  setBrandError,
  setNetworkError,
  setPassword,
  setSSOError,
  setUsername,
  submitLogin
} from '#models/login/action';

// export const scada = new Scada();
AvatarMonitor.getInstance().init();

const ieVersion = (function () {
  var userAgent = navigator.userAgent; //取得浏览器的userAgent字符串
  var isIE = userAgent.indexOf("compatible") > -1 && userAgent.indexOf("MSIE") > -1; //判断是否IE<11浏览器
  var isEdge = userAgent.indexOf("Edge") > -1 && !isIE; //判断是否IE的Edge浏览器
  var isIE11 = userAgent.indexOf('Trident') > -1 && userAgent.indexOf("rv:11.0") > -1;
  if (isIE) {
    var reIE = new RegExp("MSIE (\\d+\\.\\d+);");
    let version = userAgent.match(reIE)[1]
    var fIEVersion = parseFloat(version);
    if (fIEVersion == 7) {
      return 7;
    } else if (fIEVersion == 8) {
      return 8;
    } else if (fIEVersion == 9) {
      return 9;
    } else if (fIEVersion == 10) {
      return 10;
    } else {
      return 6;//IE版本<=7
    }
  } else if (isEdge) {
    return 'edge';//edge
  } else if (isIE11) {
    return 11; //IE11
  } else {
    return -1;//不是ie浏览器
  }
})()

const isIE = typeof ieVersion === 'number' && ieVersion > -1; //判断是否IE<11浏览器

const messages = defineMessages({
  accountPlaceholder: {
    id: 'Login.accountPlaceholder',
    defaultMessage: '手机号/邮箱',
  },
  passwordPlaceholder: {
    id: 'Login.passwordPlaceholder',
    defaultMessage: '密码',
  },
  login: {
    id: 'Login.login',
    defaultMessage: '登 录',
  },
});
const getWebTerminalInfo = () => {
  const env = get('env');
  if (env === 'WEB') {
    return true;
  }
  return false;
};
const getAppTerminalInfo = () => {
  const env = get('env');
  if (env === 'MOBILE') {
    return true;
  }
  return false;
};
const LogoWrapper = styled.div`
  padding-bottom: 37px;
  // padding-top: 32px;
`;

const SignInTipWrapper = styled.div`
  margin: 0 0 8px;
`;
const PrivatizedLogin = styled.div`
  display: none;
`;
const GeneralLogin = styled.div`
  display: block;
`;

const ClearInputUserNameBtn = styled.a`
  width: 16px;
  height: 16px;
  background: rgba(0, 0, 0, 0.1);
  color: #ffffff;
  border-radius: 50%;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  
  
  &:hover{
    background: rgba(0, 0, 0, 0.2);
 }
 
 &:before,
 &:after {
    content: ' ';
    position: absolute;
    height: 8px;
    width: 2px;
    background-color: #fff;
  }
  
  &:before {
    transform: rotate(45deg);
  }
  
  &:after {
    transform: rotate(-45deg);
  }
`

class Login extends React.Component {

  componentWillMount() {
    AvatarMonitor.getInstance().setOptions({
      platform: (get("env") || "").toLowerCase(),
    });
  }

  render() {
    const {
      onLoginClick,
      onUsernameInput,
      onPasswordInput,
      onPressEnter,
      onClearUserName,
      username,
      password,
      checkBoxRes,
      buttonLoading,
      passportError,
      tenantError,
      registerAddressError,
      SSOError,
      brandError,
      networkError,
      fatalError,
      onCloseMsgModal,
      onProtoChanged,
      loginType
    } = this.props

    let hideForgetBtn = loginType != 'miniapp'
    return (
      <Center
        showFooter
      >
        {
          isIE && <BrowserModal ieVersion={ieVersion}></BrowserModal>
        }
        {
          get('env') === 'WEB' && (
            <LogoWrapper>
              <Logo />
            </LogoWrapper>
          )
        }
        <SignInTipWrapper>
          <BigPromptTip>
            {intl.formatMessage(messages.login)}
          </BigPromptTip>
        </SignInTipWrapper>
        <div>
          <Input
            value={username}
            placeholder={intl.formatMessage(messages.accountPlaceholder)}
            onChange={onUsernameInput}
            onKeyUp={buttonLoading ? null : onPressEnter}
            type="text"
            name="username"
            pageType="login"
            data-ta-key="email_input"
            autoFocus={get('env') === 'WEB' && !isIE}
          >
            {username && <ClearInputUserNameBtn onClick={onClearUserName} />}
          </Input>
          <Input
            value={password}
            placeholder={intl.formatMessage(messages.passwordPlaceholder)}
            onChange={onPasswordInput}
            onKeyUp={buttonLoading ? null : onPressEnter}
            type="password"
            pageType="login"
            errorMsg={passportError || tenantError}
            data-ta-key="password_input"
          >
            {hideForgetBtn && <ForgetPassword />}
          </Input>
        </div>
        {/* OEM修改项请勿动 */}
        <RegisterAndPrivacy isLoginPage={true} handleChange={onProtoChanged} />

        {
          getWebTerminalInfo() &&
          (<Button
            loading={buttonLoading}
            active={username && password}
            onClick={buttonLoading ? null : onLoginClick}
            isLoginPage={true}
            data-ta-key="login_btn"
          >
            {intl.formatMessage(messages.login)}
          </Button>
          )
        }
        {
          getAppTerminalInfo() &&
          (<GeneralLogin>
            <Button
              loading={buttonLoading}
              active={username && password && checkBoxRes}
              onClick={buttonLoading ? null : onLoginClick}
              isLoginPage={true}
              data-ta-key="login_btn"
            >
              {intl.formatMessage(messages.login)}
            </Button>
          </GeneralLogin>)
        }
        {
          getAppTerminalInfo() &&
          (
            <PrivatizedLogin>
              <Button
                loading={buttonLoading}
                active={username && password}
                onClick={buttonLoading ? null : onLoginClick}
                isLoginPage={true}
                data-ta-key="login_btn"
              >
                {intl.formatMessage(messages.login)}
              </Button>
            </PrivatizedLogin>
          )
        }


        <ExternalLogin />
        <MsgModal
          show={
            (!!SSOError)
            || (!!brandError)
            || (!!registerAddressError)
            || (!!networkError)
            || (!!fatalError)
          }
          msg={
            SSOError
            || brandError
            || registerAddressError
            || networkError
            || fatalError
          }
          onClose={onCloseMsgModal}
        />
      </Center>
    )
  }
}

Login.propTypes = {
  onLoginClick: PropTypes.func.isRequired,
  onUsernameInput: PropTypes.func.isRequired,
  onPasswordInput: PropTypes.func.isRequired,
  onCloseMsgModal: PropTypes.func.isRequired,
  onPressEnter: PropTypes.func.isRequired,
  onClearUserName: PropTypes.func.isRequired,
  username: PropTypes.string.isRequired,
  password: PropTypes.string.isRequired,
  buttonLoading: PropTypes.bool.isRequired,
  passportError: PropTypes.string,
  tenantError: PropTypes.string,
  registerAddressError: PropTypes.string,
  SSOError: PropTypes.string,
  brandError: PropTypes.string,
  networkError: PropTypes.string,
  fatalError: PropTypes.string,
  checkBoxRes: PropTypes.bool,
};

Login.defaultProps = {
  passportError: null,
  tenantError: null,
  registerAddressError: null,
  SSOError: null,
  brandError: null,
  networkError: null,
  fatalError: null,
};

// connect组件
function mapStateToProps(state) {
  return {
    username: state.username,
    password: state.password,
    buttonLoading: state.loginLoading,
    passportError: state.passportError,
    tenantError: state.tenantError,
    registerAddressError: state.registerAddressError,
    SSOError: state.SSOError,
    brandError: state.brandError,
    networkError: state.networkError,
    fatalError: state.fatalError,
    globalError: state.globalError,
    checkBoxRes: state.checkBoxRes,
    loginType: state.loginType
  };
}

function mapDispatchToProps(dispatch) {
  return {

    onLoginClick() {
      dispatch(submitLogin());
    },
    onUsernameInput(e) {
      dispatch(setUsername(e.target.value.trim()));
    },
    onProtoChanged(e) {
      dispatch(protocalChanged(e.target.checked))
    },
    onPasswordInput(e) {
      dispatch(setPassword(e.target.value));
    },
    onCloseMsgModal() {
      dispatch(setSSOError(null));
      dispatch(setBrandError(null));
      dispatch(setNetworkError(null));
      dispatch(handleFatalError());
    },
    onClearUserName() {
      dispatch(clearUserName());
    },
    onPressEnter(e) {
      if (e.keyCode === 13 && get('env') == 'WEB') {
        dispatch(submitLogin());
      }
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(withTips(Login));
